import React from "react";

import SEO from "../components/seo/seo";

import './404.scss';

const NotFoundPage = () => (
    <div className="not-found">
        <SEO title="Page Not found - TOMMY GENES" />
        <h1 className={"not-found__text font-primary"}>Page Not Found</h1>
    </div>
);

export default NotFoundPage;
